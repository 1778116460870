import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import Footer from '@ds.e/react/lib/atomic/organisms/Footer/Footer'
import { useDisplayFooter } from '@hooks/useDisplayFooter'
import BrandLogo from 'assets/icons/BrandLogo'
import { WhatsappIcon } from 'assets/icons/WhatsappIcon'
import { PhoneIcon } from 'assets/icons/PhoneIcon'
import { useAppContext } from '@hooks/useAppContext'
import { PATHS } from '@constants/paths'

import '@ds.e/scss/lib/Label.css'
import '@ds.e/scss/lib/Link.css'
import '@ds.e/scss/lib/Accordion.css'
import '@ds.e/scss/lib/Footer.css'
import './FooterWrapper.scss'

const LINKS_DATA = [
    {
        title: 'Contáctanos',
        links: [
            {
                name: '+569 5098 8060',
                ariaLabel: 'Número de Whatsapp',
                icon: <WhatsappIcon />,
                url: 'https://wa.me/++56950988060',
            },
            {
                name: '600 945 9800',
                ariaLabel: 'Número de teléfono',
                icon: <PhoneIcon />,
                url: 'tel:6009459800',
            },
        ],
    },
    {
        title: 'Legales',
        links: [
            {
                name: 'Términos y Condiciones',
                ariaLabel: 'Terminos y condiciones',
                url: `${PATHS.TERMS_AND_CONDITIONS}`,
                target: '_self',
            },
        ],
    },
]

const EXTRA_LINK_DATA = [
    {
        title: '© Copyright Metlife 2024',
        ariaLabel: '© Copyright Metlife 2024',
    },
]

const FooterWrapper = () => {
    const { appState } = useAppContext()
    const navigate = useNavigate()
    const location = useLocation()
    const handleNavigate = () => {
        if (location.pathname === PATHS.ROOT) {
            window.scrollTo(0, 0)
        }
        navigate(PATHS.ROOT)
    }

    return useDisplayFooter() ? (
        <Footer
            linksData={LINKS_DATA}
            extraLinkData={EXTRA_LINK_DATA}
            brandLogo={<BrandLogo navigate={handleNavigate} />}
            device={appState.viewportSize}
            accordionsAreCollapsed
        />
    ) : null
}

export default FooterWrapper
